var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "v-card",
    {
      staticClass: "d-flex align-stretch flex-column",
      attrs: { disabled: _vm.option.disabled }
    },
    [
      _c("psi-card-heading", {
        attrs: { icon: _vm.option.icon, headingTitle: _vm.option.title }
      }),
      _c("v-card-text", [
        _c(
          "div",
          { staticClass: "d-flex align-center" },
          [
            _vm.$_.has(_vm.option, "logo")
              ? _c("img", {
                  attrs: { width: "60", height: "60", src: _vm.option.logo }
                })
              : _c(
                  "v-avatar",
                  { attrs: { size: "60", color: _vm.option.color } },
                  [
                    _c(
                      "v-icon",
                      { staticClass: "white--text", attrs: { large: "" } },
                      [_vm._v(_vm._s(_vm.option.icon))]
                    )
                  ],
                  1
                ),
            _c(
              "v-alert",
              {
                staticClass: "mt-2 ml-2",
                attrs: {
                  height: "100",
                  prominent: "",
                  color: "primary",
                  outlined: "",
                  border: "top"
                }
              },
              [_vm._v(_vm._s(_vm.option.alert) + " ")]
            )
          ],
          1
        )
      ]),
      _c(
        "v-card-actions",
        [
          _c("v-spacer"),
          _c(
            "v-btn",
            { staticClass: "mr-2", attrs: { color: "primary" } },
            [
              _c("v-icon", { staticClass: "mr-1" }, [
                _vm._v(_vm._s(_vm.option.icon))
              ]),
              _vm._v(" " + _vm._s(_vm.option.action) + " ")
            ],
            1
          )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }