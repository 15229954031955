<template>
    <v-card
        :disabled="option.disabled"
        class="d-flex align-stretch flex-column"
    >
        <psi-card-heading
            :icon="option.icon"
            :headingTitle="option.title"
        ></psi-card-heading>
        <v-card-text>
            <div class="d-flex align-center">
                <img
                    v-if="$_.has(option, 'logo')"
                    width="60"
                    height="60"
                    :src="option.logo"
                />
                <v-avatar v-else size="60" :color="option.color">
                    <v-icon large class="white--text">{{ option.icon }}</v-icon>
                </v-avatar>
                <v-alert
                    class="mt-2 ml-2"
                    height="100"
                    prominent
                    color="primary"
                    outlined
                    border="top"
                    >{{ option.alert }}
                </v-alert>
            </div>
        </v-card-text>
        <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn color="primary" class="mr-2">
                <v-icon class="mr-1">{{ option.icon }}</v-icon>
                {{ option.action }}
            </v-btn>
        </v-card-actions>
    </v-card>
</template>
<script>
export default {
    name: "new-application-option",
    components: {},
    props: {
        option: {
            type: Object,
            required: true,
        },
    },
    data() {
        return {};
    },
};
</script>

<style scoped>
</style>